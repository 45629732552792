const ERROR_STATUS = {
    NoManagedLocations: 'No managed locations found for this vin',
    TelemetryNotAvailable: 'Telemetry not available for this vin',
    NotFordEnergyRewardsCustomer: 'Not Enrolled in Ford Energy Rewards',
    FailedToFetchVehicleStatus: 'Failed to fetch vehicle status',
    NoAccess: 'Forbidden',
    Unexpected: 'Unexpected Error'
};

export default ERROR_STATUS;
