import React from 'react';
import { Modal } from 'react-bootstrap';
import './PopupModal.scss';

export default function PopupModal({ displayDialog, closeDialog, dialogTitle, dialogTextList }) {
    return (
        <Modal id="popup-modal" show={displayDialog} onHide={closeDialog} animation={true} centered>
            <Modal.Header closeButton closeVariant="white">
                <span>{dialogTitle}</span>
            </Modal.Header>
            <Modal.Body>
                {/*TODO deal with array, do we need it*/}
                {dialogTextList.map((text, index) => {
                    return (
                        <p key={index}>
                            {text}
                            <br />
                        </p>
                    );
                })}
            </Modal.Body>
        </Modal>
    );
}
