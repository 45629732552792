import React, { useEffect, useState } from 'react';
import './EnergyFlow.scss';
import EnergyFlowDiagram from '../../assets/images/Energy_flow_empty.svg';
import EnergyFlowNotPluggedIn from '../../assets/images/Energy_flow_not_plugged_in.svg';
import EnergyFlowOutageOrange from '../../assets/images/Energy_flow_outage_orange.svg';
import EnergyFlowOutageGreen from '../../assets/images/Energy_flow_outage_green.svg';
import VEHICLE_STATUS from '../../constants/VehicleStatusConst';
import UserType from '../../constants/UserTypeConst.js';
import config from '../../env/environment.js';

export default function EnergyFlow({ chargingStatus, userType, powerOutage }) {
    const VIN = sessionStorage.getItem('VIN');
    const [dischargeRateKw, setDischargeRateKw] = useState();

    const fetchDischargeRate = () => {
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/discharge-rate/${VIN}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => response.json())
            .then((data) => {
                setDischargeRateKw(parseFloat(data.dischargeRateKw).toFixed(1));
            })
            .catch((error) => {
                console.error('Error getting IntelligentBackupPower ', error);
            });
    };

    useEffect(() => {
        if (chargingStatus === VEHICLE_STATUS.PoweringHome) {
            fetchDischargeRate();
            const interval = setInterval(fetchDischargeRate, 30000);
            return () => clearInterval(interval);
        }
    }, [chargingStatus]);

    if (chargingStatus === VEHICLE_STATUS.NotPluggedIn) {
        return (
            <div className="bar-container">
                <img
                    src={EnergyFlowNotPluggedIn}
                    className="energy-diagram"
                    alt="Energy flow not plugged in"
                />
            </div>
        );
    } else if (chargingStatus === VEHICLE_STATUS.Charging) {
        return (
            <div className="bar-container">
                <img
                    className="energy-diagram"
                    src={EnergyFlowDiagram}
                    alt="Energy flow charging"
                />
                <div className="flow-animation-top">
                    <div className="flow-down" />
                </div>
                <div className="flow-animation-bottom">
                    <div className="flow-down" />
                </div>
            </div>
        );
    } else if (
        chargingStatus === VEHICLE_STATUS.PoweringHome &&
        userType !== UserType.FORD_ENERGY_REWARDS &&
        powerOutage
    ) {
        return (
            <div className="bar-container">
                <img
                    className="energy-diagram"
                    src={EnergyFlowOutageGreen}
                    alt="Energy flow outage detected"
                />
                {dischargeRateKw && <div className="discharge-rate">{dischargeRateKw}kW</div>}
                <div className="flow-animation-bottom">
                    <div className="flow-up-green" />
                </div>
            </div>
        );
    } else if (chargingStatus === VEHICLE_STATUS.PoweringHome) {
        return (
            <div className="bar-container">
                <img
                    className="energy-diagram"
                    src={EnergyFlowDiagram}
                    alt="Energy flow powering home"
                />
                {dischargeRateKw && <div className="discharge-rate">{dischargeRateKw}kW</div>}
                <div className="flow-animation-bottom">
                    <div className="flow-up-green" />
                </div>
            </div>
        );
    } else if (chargingStatus === VEHICLE_STATUS.OutageDetected) {
        return (
            <div className="bar-container">
                <img
                    className="energy-diagram"
                    src={EnergyFlowOutageOrange}
                    alt="Energy flow outage detected"
                />
                <div className="flow-animation-bottom">
                    <div className="flow-up-orange" />
                </div>
            </div>
        );
    } else {
        return (
            <div className="bar-container">
                <img src={EnergyFlowDiagram} className="energy-diagram" alt="Energy flow waiting" />
            </div>
        );
    }
}
