import React from 'react';
import { Button } from 'react-bootstrap';
import './SaveButton.scss';

export default function SaveButton({ isSaveButtonDisabled, onClick, id }) {
    return (
        <div className="save-button-sticky-container">
            <Button
                className="save-button"
                id={id}
                variant="secondary"
                disabled={isSaveButtonDisabled}
                onClick={onClick}
            >
                Save My Changes
            </Button>
        </div>
    );
}
