import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function ConfirmationOfExitWithoutSaving({
    showLeaveWithChangesConfirmation,
    onYes,
    onNo
}) {
    return (
        <Modal show={showLeaveWithChangesConfirmation} animation={true} centered>
            <Modal.Header>
                <span>Discard Changes?</span>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to exit without saving?</p>
                <div className="d-flex justify-content-around my-3">
                    <Button
                        className="option-button"
                        id="yes-button-to-exit-without-saving"
                        variant="light"
                        onClick={onYes}
                    >
                        Yes
                    </Button>
                    <Button
                        className="option-button"
                        id="no-button-to-exit-without-saving"
                        variant="light"
                        onClick={onNo}
                    >
                        No
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
