import React, { useEffect, useState } from 'react';
import './BackupPowerTile.scss';
import DischargeModal from '../DischargeModal/DischargeModal.jsx';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Card, Stack } from 'react-bootstrap';
import {
    editBackupPowerAtom,
    fetchLoadingAtom,
    modeAtom,
    rangeReserveAtom,
    vehicleStateAtom,
    showEditBackupPowerErrorDialogAtom
} from '../../atoms/vehicleState';
import userTypeConst from '../../constants/UserTypeConst.js';
import ChargeModal from '../ChargeModal/ChargeModal.jsx';
import EditRangeReserve from '../EditRangeReserve/EditRangeReserve.jsx';
import PopupModal from '../PopupModal/PopupModal.jsx';
import { editBackupPowerPost } from './EditBackupPowerPost.js';

export default function BackupPowerTile({ isSideMenu, userType }) {
    const VIN = sessionStorage.getItem('VIN');
    const token = sessionStorage.getItem('catToken');
    const loading = useRecoilValue(fetchLoadingAtom);
    const vehicleState = useRecoilValue(vehicleStateAtom);
    const [editBackupPowerInProgress, setEditBackupPowerInProgress] =
        useRecoilState(editBackupPowerAtom);
    const [newRangeReserveValue] = useRecoilState(rangeReserveAtom);
    const [newModeValue] = useRecoilState(modeAtom);
    const [showErrorDialog, setShowErrorDialog] = useRecoilState(
        showEditBackupPowerErrorDialogAtom
    );

    const showBackupPowerTile = () => {
        return (
            vehicleState.reserve !== undefined &&
            vehicleState.reserve !== null &&
            vehicleState.reserve !== 0
        );
    };

    const showOrHideDialog = (showDialog) => {
        setEditBackupPowerInProgress(false);
        setShowErrorDialog(showDialog);
    };

    const editBackupPower = (rangeReserve, mode) => {
        editBackupPowerPost(VIN, token, rangeReserve, mode, showOrHideDialog);
    };

    useEffect(() => {
        if (
            editBackupPowerInProgress &&
            newModeValue === vehicleState.mode &&
            newRangeReserveValue === vehicleState.reserve
        ) {
            setEditBackupPowerInProgress(false);
        }
    }, [vehicleState]);

    if (!loading && showBackupPowerTile()) {
        if (isSideMenu) {
            return (
                <EditRangeReserve
                    isUpdateInProgress={editBackupPowerInProgress}
                    updateBackupPower={editBackupPower}
                    isHomePageFlow={false}
                />
            );
        } else {
            return (
                <div className="backup-power-tile">
                    <PopupModal
                        className="backup-power-modal"
                        displayDialog={showErrorDialog}
                        closeDialog={() => showOrHideDialog(false)}
                        dialogTitle={'Backup Power Settings Error'}
                        dialogTextList={[
                            'Backup Power Settings update was not successful. Please try again.'
                        ]}
                    />
                    <Card
                        className="rounded-card col-card"
                        id="backup-power-tile-card"
                        style={{ opacity: editBackupPowerInProgress ? '0.5' : '1' }}
                    >
                        <Stack direction="horizontal" className="p-1" gap={3}>
                            <Stack direction="vertical" className="backup-power-section">
                                <div className="backup-power-tile-header">Home Backup Power</div>
                                <div className="text-row-2 w-45">
                                    {editBackupPowerInProgress
                                        ? `${newRangeReserveValue} mi`
                                        : `${vehicleState.reserve} mi`}
                                </div>
                                <div className="text-row-3 w-45">range reserve</div>
                            </Stack>
                            <Stack direction="vertical" className="backup-power-section">
                                <EditRangeReserve
                                    isUpdateInProgress={editBackupPowerInProgress}
                                    updateBackupPower={editBackupPower}
                                    isHomePageFlow={true}
                                />
                                {vehicleState.chargeModal.buttonVisible &&
                                    userType === userTypeConst.HOME_BACKUP_POWER && (
                                        <ChargeModal
                                            buttonLabel={vehicleState.chargeModal.buttonLabel}
                                            buttonDisabled={vehicleState.chargeModal.buttonDisabled}
                                            userType={userType}
                                            scheduleCalculated={true} //Backup Power button doesn't have a schedule, chargeModal will disable schedule if false though and we don't want that to happen
                                            data-testid="chargemodal-component"
                                        />
                                    )}
                                {vehicleState.dischargeModal.buttonVisible && (
                                    <DischargeModal
                                        buttonLabel={vehicleState.dischargeModal.buttonLabel}
                                        buttonDisabled={vehicleState.dischargeModal.buttonDisabled}
                                        powerOutage={vehicleState.powerOutage}
                                        data-testid="dischargemodal-component"
                                    />
                                )}
                            </Stack>
                        </Stack>
                    </Card>
                </div>
            );
        }
    }
}
