import config from '../../env/environment.js';

export const editTargetChargePost = (VIN, token, newTsoc, showOrHideDialog, onSuccess) => {
    fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/target-charge/${VIN}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Auth-Token': token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ targetSoc: newTsoc })
    })
        .then((response) => {
            if (response.ok) {
                return response.json().then((data) => {
                    if (data.status === 'SUCCESS') {
                        onSuccess();
                        return data;
                    } else {
                        throw new Error('Target charge update was not successful.');
                    }
                });
            } else {
                throw new Error(response);
            }
        })
        .catch((error) => {
            showOrHideDialog(true);
        });
};
