import React, { useEffect, useState } from 'react';
import './ErrorPage.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Button } from 'react-bootstrap';
import config from '../../env/environment.js';
import VEHICLE_STATUS from '../../constants/VehicleStatusConst';
import NotAuthorizedImage from '../../assets/icons/Not_authorized.svg';
import WarningIcon from '../../assets/icons/Generic_Warning_icon.svg';
import ERROR_STATUS from '../../constants/ErrorStatusConst';
import Logout from '../../util/Logout';
import PrivacyLinks from '../../components/PrivacyLinks/PrivacyLinks.jsx';
import { MAILTO_LINK } from '../../constants/HelpLinks.js';

export default function ErrorPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [errorStatus, setErrorStatus] = useState(location.state);
    const VIN = sessionStorage.getItem('VIN');

    const getVehicleStatus = () => {
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/vehicle-status/${VIN}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === 401) {
                    navigate('/welcome');
                }
                if (response.errorResponse) {
                    console.log('Telemetry not available for this vin');
                    setErrorStatus(ERROR_STATUS.TelemetryNotAvailable);
                }
                if (
                    response.chargingStatusEnums &&
                    Object.values(VEHICLE_STATUS).includes(response.chargingStatusEnums)
                ) {
                    navigate('/');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (errorStatus !== ERROR_STATUS.NotFordEnergyRewardsCustomer) {
            const interval = setInterval(() => {
                getVehicleStatus();
            }, 20000);
            return () => clearInterval(interval);
        }
    }, []);

    const renderErrorMessage = () => {
        if (errorStatus === ERROR_STATUS.TelemetryNotAvailable) {
            return (
                <div className="error-page">
                    <p className="error-status-text">
                        Data is not available for this vehicle at the moment.
                    </p>
                    <PrivacyLinks data-test-id="privacy-links" />
                </div>
            );
        } else if (errorStatus === ERROR_STATUS.NotFordEnergyRewardsCustomer) {
            return (
                <div className="error-page">
                    <img
                        src={NotAuthorizedImage}
                        className="icon-image"
                        alt="not authorized icon"
                    />
                    <p className="error-status-text">
                        We are still working on this experience. Join{' '}
                        <a href="mailto:myenergy@ford.com?Subject=Join Waitlist">our waitlist</a> to
                        get an early glimpse!
                    </p>
                    <PrivacyLinks data-test-id="privacy-links" />
                </div>
            );
        } else if (errorStatus === ERROR_STATUS.FailedToFetchVehicleStatus) {
            return (
                <div className="error-page">
                    <p className="error-status-text">Failed to retrieve vehicle data.</p>
                    <PrivacyLinks data-test-id="privacy-links" />
                </div>
            );
        } else if (errorStatus === ERROR_STATUS.NoAccess) {
            return (
                <div className="error-page">
                    <img
                        src={NotAuthorizedImage}
                        className="icon-image"
                        alt="not authorized icon"
                    />

                    <p className="status-text">
                        Sorry, you do not have access to this Ford Energy Management page.
                    </p>
                    <PrivacyLinks data-test-id="privacy-links" />
                </div>
            );
        } else {
            return (
                <div className="error-page">
                    <img
                        src={WarningIcon}
                        className="icon-image error-triangle"
                        alt="warning icon"
                    />
                    <p className="error-status-text text-lg">
                        Sorry, we have encountered an unexpected error.
                    </p>
                    <PrivacyLinks data-test-id="privacy-links" />
                </div>
            );
        }
    };

    return (
        <Stack className="error-status justify-content-center vh-100 mx-auto" gap={2}>
            {renderErrorMessage()}
            <Button className="login-button mx-auto mt-4" onClick={Logout}>
                Sign Out
            </Button>
            <h4 className="contact-text mx-auto">
                Having trouble? <a href={MAILTO_LINK}>Contact Us.</a>
            </h4>
        </Stack>
    );
}
